export const languages = {
  NL: {
    textAboveForm: [
      {
        title: 'Een betere prijs voor uw zonnestroom !',
        paragraphs: [
          `Heeft u zonnepanelen en een digitale meter? U kan dan uw overschot aan zonnestroom verkopen aan Wijnegem - Shop Eat Enjoy, Waasland Shopping of K in Kortrijk en de vergoeding hiervoor ontvangen op een giftcard.`,
          `Zonnepaneleneigenaars die geen recht (meer) hebben op een terugdraaiende teller, moeten voortaan hun stroom verkopen op momenten dat ze meer produceren dan ze op datzelfde moment verbruiken. Elektriciteitsleveranciers bieden voor die stroom slechts een beperkte vergoeding en meestal is het daaraan verbonden afnamecontract veel duurder.  Zero  Emission  Solutions  ontwikkelde  een  model  waarbij  die  zonnestroomoverschotten  rechtstreeks worden   overgenomen   door   verbruikers   zoals   bedrijven,   organisaties   of   andere   particulieren   binnen   een energiegemeenschap. Zo krijgen prosumenten een betere prijs voor hun zonnestroom`,
          `Het  tegoed  die  de  klant  krijgt  van  het  shoppingcentrum  voor  zijn  geïnjecteerde  zonnestroom  bedraagt  7 eurocent/kWh. Het shoppingcentrum kan de waarde voor de geleverde zonnestroom op een giftcard zetten bij een minimum  gecumuleerde  vergoeding  van  €10.  De  giftcard  kan  de  klant  ter  plekke  aan  de  infobalie  van  het shoppingcentrum aanvragen en ophalen.`
        ]
      },
      {
        title: 'Vanaf wanneer kan dit ?',
        paragraphs: [
          `Vanaf  12/02/2022  kunnen  klanten  hun  overtollige  zonnestroom  verkopen  binnen  een  energiegemeenschap  met Wijnegem - Shop Eat Enjoy, Waasland Shopping of K in Kortrijk.`
        ]
      },
      {
        title: 'Wie komt in aanmerking ?',
        paragraphs: [
          `Vlaamse zonnepaneleneigenaars die een digitale meter hebben en geen recht hebben op (virtuele) terugdraaiing kunnen toetreden tot de energiegemeenschap met een van de shoppingcentra. Waalse en Brusselse prosumenten kunnen voorlopig nog niet toetreden, omdat het wetgevend kader noch de netbeheerder hierrond klaar is.`
        ]
      },
      {
        title: 'Hoe werkt het ?',
        paragraphs: [
          `U schrijft zich in op deze website en u kiest aan welk shoppingcentrum u uw stroom wenst te verkopen. Na uw registratie  dient  u  een  mandaat  te  verlenen  aan  Zero  Emission  Solutions  bij  Fluvius.  Dit  mandaat  staat  Zero Emission  Solutions  toe  om  bij  Fluvius  dagelijks  de  injectiegegevens  van  de  klant  op  te  halen  in  functie  van  de verkoop van de overtollige zonnestroom. Zero Emission Solutions haalt de injectiedata (= de gegevens m.b.t. uw stroomoverschot die u op het elektriciteitsnet heeft geïnjecteerd) op bij Fluvius en geeft die door aan Wijnegem - Shop Eat Enjoy, Waasland Shopping of K in Kortrijk. Het tegoed die u voor uw geïnjecteerde zonnestroom krijgt bedraagt 7 eurocent/kWh. Het shoppingcentrum kan de waarde voor de geleverde zonnestroom op een giftcard zetten bij een minimum gecumuleerde vergoeding van €10. De giftcard kan de klant ter plekke aan de infobalie van het shoppingcentrum aanvragen en ophalen. De giftcard kan enkel gebruikt worden in Wijnegem - Shop Eat Enjoy, Waasland Shopping of K in Kortrijk. Het aantal klanten dat kan toetreden tot de energiegemeenschap is limitatief, dus wie het eerst komt, het eerst maalt.`
        ]
      },
      {
        title: 'Vragen ?',
        paragraphs: [
          'Heeft u nog vragen? Stuur dan een mail naar info@zeroemissionsourcing.com en wij antwoorden u binnen de 48 u.'
        ]
      }
    ],
    register: 'Registreer',
    ean_code: 'EAN Code (te vinden op uw elektriciteitsfactuur)',
    first_name: 'Voornaam',
    last_name: 'Naam',
    address: 'Straat',
    number_address: 'Nummer',
    postal_code: 'Postcode',
    city: 'Plaats',
    email: 'Email adres',
    phone: 'Telefoonnummer',
    card_id: 'Nummerklantenkaart',
    source: 'Ik schrijf me in via',
    source_1: 'Decathlon',
    source_2: 'Izen',
    source_3: 'Mijn gemeente',
    ikVerkoop: 'Ik verkoop mijn stroom aan:',
    IkGeef:
      'Ik geef hierbij toestemming aan Zero Emission Solutions om bij Fluvius dagelijks mijn injectiegegevens op te halen i.f.v. de verkoop van mijn zonnestroom. Deze injectiedata en mijn contactgegevens mogen tot een jaar na beëindiging van de overeenkomst worden bijgehouden, maar moeten dan vernietigd worden.',
    submit: 'Opslaan',
    field_required: 'Verplicht veld',
    email_required: 'E-mailadres is ongeldig',
    regions: 'Regio',
    table: {
      name: 'Naam energiegemeenschap',
      details: 'Details',
      show_more: 'Toon Details',
      to_who: 'Aan wie ?',
      value: 'Injectieprijs',
      date: 'Datum inwerkingtreding',
      duration_contract: 'Duur contract'
    }
  },
  FR: {
    textAboveForm: [
      {
        title: 'Un meilleur prix pour votre énergie solaire !',
        paragraphs: [
          `Vous avez des panneaux solaires et un compteur digital ? Vous pouvez ensuite vendre votre excédent d'énergie solaire à Wijnegem - Shop Eat Enjoy, Waasland Shopping ou K à Kortrijk et recevoir la compensation sur une carte-cadeau.`,
          `Désormais, les propriétaires de panneaux solaires qui n'ont plus droit à un compteur inverseur devront revendre leur électricité lorsqu'ils produisent plus qu'ils ne consomment en même temps. Les fournisseurs d'électricité n'offrent qu'une compensation limitée pour cette électricité et le contrat d'achat associé est généralement beaucoup plus cher. Zero Emission Solutions a développé un modèle dans lequel ces excédents d'énergie solaire sont directement pris en charge par les consommateurs tels que les entreprises, les organisations ou d'autres particuliers au sein d'une communauté énergétique. De cette façon, les prosommateurs obtiennent un meilleur prix pour leur énergie solaire.`,
          `Le crédit que le client reçoit du centre commercial pour sa puissance solaire injectée est de 7 centimes d'euro/kWh. Le centre commercial peut mettre la valeur de l'énergie solaire fournie sur une carte cadeau avec un minimum cumulé de 10 €. Le client peut demander et retirer la carte cadeau sur place au guichet d'information du centre commercial.`
        ]
      },
      {
        title: 'A partir de quand est-ce possible ?',
        paragraphs: [
          `À partir du 12/02/2022, les clients peuvent vendre leur excédent d'énergie solaire au sein d'une communauté énergétique avec Wijnegem - Shop Eat Enjoy, Waasland Shopping ou K à Kortrijk.`
        ]
      },
      {
        title: 'Qui se qualifie ?',
        paragraphs: [
          `Les propriétaires flamands de panneaux solaires qui disposent d'un compteur numérique et qui n'ont pas droit à un rollback (virtuel) peuvent rejoindre la communauté énergétique avec l'un des centres commerciaux. Les prosommateurs wallons et bruxellois ne peuvent pas adhérer pour le moment, car ni le cadre législatif ni le gestionnaire de réseau ne sont prêts.`
        ]
      },
      {
        title: 'Comment ça marche ?',
        paragraphs: [
          `Vous vous inscrivez sur ce site et vous choisissez à quel centre commercial vous souhaitez vendre votre électricité. Après votre inscription, vous devez accorder un mandat à Zero Emission Solutions chez Fluvius. Ce mandat permet à Zero Emission Solutions de collecter quotidiennement les données d'injection du client auprès de Fluvius, sur la base de la vente de l'énergie solaire excédentaire. Zero Emission Solutions collecte les données d'injection (= les données relatives à votre surplus d'électricité que vous avez injecté dans le réseau électrique) de Fluvius et les transmet à Wijnegem - Shop Eat Enjoy, Waasland Shopping ou K à Kortrijk. Le crédit que vous recevez pour votre énergie solaire injectée est de 7 centimes d'euro/kWh. Le centre commercial peut mettre la valeur de l'énergie solaire fournie sur une carte cadeau avec un minimum cumulé de 10 €. Le client peut demander et retirer la carte cadeau sur place au guichet d'information du centre commercial. La carte-cadeau ne peut être utilisée qu'à Wijnegem - Shop Eat Enjoy, Waasland Shopping ou K à Kortrijk. Le nombre de clients pouvant rejoindre la communauté de l'énergie est limité, c'est donc premier arrivé, premier servi.`
        ]
      },
      {
        title: 'Des questions ?',
        paragraphs: [
          `Avez-vous des questions? Envoyez un email à info@zeroemissionsourcing.com et nous vous répondrons dans les 48 heures.`
        ]
      }
    ],
    register: 'Enregistez vous',
    ean_code: 'EAN Code: a voir sur votre facture d’électricité',
    first_name: 'Prénom',
    last_name: 'Nom',
    address: 'Rue',
    number_address: 'Numéro',
    postal_code: 'Code Postale',
    city: 'Lieu',
    email: 'Adresse email',
    phone: 'Numéro de téléphone',
    card_id: 'Numéro de carte de fidélité',
    source: "Je m'inscris via",
    source_1: 'Decathlon',
    source_2: 'Izen',
    source_3: 'Ma commune',
    ikVerkoop: 'Je vends ma puissance:',
    IkGeef:
      "Par la présente, j'autorise Zero Emission Solutions à collecter quotidiennement mes données d'injection auprès de Fluvius. vendre mon énergie solaire. Ces dates d'injection et mes coordonnées peuvent être conservées jusqu'à un an après la résiliation du contrat, mais doivent alors être détruites.",
    submit: 'Enrégistrer',
    field_required: 'Champs requis',
    email_required: 'E-mailadres is ongeldig',
    regions: 'Région',
    table: {
      name: 'Nom de la communauté énergétique',
      details: 'Détails',
      show_more: 'Voir plus',
      to_who: 'À qui?',
      value: "Prix ​​d'injection",
      date: 'Date effective',
      duration_contract: 'Durée du contrat'
    }
  }
};
