<script>
  import Header from './components/Header.svelte';
  import Footer from './components/Footer.svelte';
  import RegistrationPage from './components/RegistrationPage.svelte';
  import EmailSent from './components/EmailSent.svelte';
  import { successRegistration } from './store/Store';

  let isRegistered = false;
  successRegistration.subscribe(value => {
    // console.log(value);
    isRegistered = value;
  });
</script>

<Header />

<main>
  {#if !isRegistered}
    <RegistrationPage />
  {/if}
</main>
{#if isRegistered}
  <EmailSent />
{/if}
<Footer />

<style>
  main {
    min-height: calc(100vh - 170px);
    max-width: 600px;
    margin: 0 auto;
    padding: 20px 0 40px;
  }
</style>
