<script>
  import { languages } from '../intl/languages.util';
  import { currentLanguage } from '../store/Store';

  let paragraphs;

  currentLanguage.subscribe(value => (paragraphs = languages[value].textAboveForm));
</script>

{#each paragraphs as p (p.title)}
  <h3>{p.title}</h3>
  {#each p.paragraphs as subparagraph}
    <p>{subparagraph}</p>
  {/each}
{/each}

<style>
  h3 {
    font-size: 15px;
    font-weight: 600;
  }
</style>
