<script>
  import { createEventDispatcher, onMount } from 'svelte';

  import ENV from '../env';
  import { currentLanguage } from '../store/Store';
  import Loader from './Loader.svelte';

  const dispatch = createEventDispatcher();

  export let tableLabels;
  let selected;

  let currentLang;
  let retailers = [];
  let showDetails = [];
  let isPending = false;

  currentLanguage.subscribe(value => {
    currentLang = value;
    fetchData(value);
  });

  function fetchData(currentLang) {
    isPending = true;
    fetch(`${ENV.API_URL}retailers/?&source=Shopping&language=${currentLang}`)
      .then(response => response.json())
      .then(data => {
        retailers = data;
        isPending = false;
        data.forEach(el => {
          el.units = typeof el.injection_price === 'number' ? '€/kWh' : '';
        });
      })
      .catch(console.error);
  }

  onMount(() => {
    fetchData(currentLang);
  });

  const selectRetailer = retailer => {
    dispatch('selectRetailer', { retailer });
  };
</script>

<div class="table-container">
  <table>
    <thead>
      <tr>
        <th width="280">{tableLabels.name}</th>
        <th>{tableLabels.details}</th>
      </tr>
    </thead>
    <tbody>
      {#each retailers as retailer (retailer.id)}
        <tr class:active={selected === retailer}>
          <td>
            <div class="retailer">
              <input
                type="radio"
                bind:group={selected}
                on:change={() => selectRetailer(selected)}
                id={retailer.id}
                name="retailer"
                value={retailer}
              />
              <label for={retailer.id}>{retailer.name}</label>
            </div>
          </td>
          <td class="show-more" on:click={() => (showDetails[retailer.id] = !showDetails[retailer.id])}
            >{tableLabels.show_more}</td
          >
        </tr>
        {#if showDetails[retailer.id]}
          <tr class="row-details">
            <td>{tableLabels.to_who}</td>
            <td colspan="2">{retailer.intended_for}</td>
          </tr>
          <tr class="row-details">
            <td>{tableLabels.value}</td>
            <td colspan="2">{retailer.injection_price} {retailer.units}</td>
          </tr>
          <tr class="row-details">
            <td>{tableLabels.date}</td>
            <td colspan="2">{retailer.start_date}</td>
          </tr>
          <tr class="row-details">
            <td>{tableLabels.duration_contract}</td>
            <td colspan="2">{retailer.contract_duration}</td>
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>

{#if isPending}
  <Loader />
{/if}

<style>
  table {
    width: 100%;
    margin-bottom: -1px;
    border-collapse: collapse;
  }

  thead {
    color: #737373;
  }

  td {
    padding: 18px 5px 18px 15px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input[type='radio'] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    cursor: pointer;
  }

  .retailer {
    display: flex;
  }

  .show-more {
    text-align: center;
    cursor: pointer;
  }

  th {
    padding: 10px 5px 10px 15px;
    font-weight: normal;
  }

  td,
  th {
    border-bottom: 1px solid #d9d9d9;
  }

  .active {
    background-color: #ebf1f5;
  }

  .row-details {
    background-color: #f5f8fa;
  }

  .table-container {
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    overflow-x: auto;
    border: 1px solid #d9d9d9;
    margin-top: 20px;
  }
</style>
