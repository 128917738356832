<script>
  import RetailersForm from './RetailersForm.svelte';
  import { onMount } from 'svelte';
  import ENV from '../env';
  import Loader from './Loader.svelte';
  import { languages } from '../intl/languages.util';
  import { currentLanguage, successRegistration } from '../store/Store';
  import { OpenStreetMapProvider } from 'leaflet-geosearch';
  import ErrorSnackbar from './ErrorSnackbar.svelte';
  import SearchSelect from './SearchSelect.svelte';

  let labels;
  let errorMessage = [];
  let timer;

  const osmp = new OpenStreetMapProvider();

  let form = {
    type: { value: 'producer', isFocused: false },
    ean_code: { value: '54', isFocused: false },
    first_name: { value: '', isFocused: false },
    last_name: { value: '', isFocused: false },
    address: { value: '', isFocused: false },
    address_number: { value: '', isFocused: false },
    city: { value: '', isFocused: false },
    email: { value: '', isFocused: false },
    phone: { value: '', isFocused: false },
    card_id: { value: '', isFocused: false },
    retailer: { value: '', isFocused: false },
    language: { value: '', isFocused: false },
    lat: { value: '50.5039', isFocused: false },
    long: { value: '4.4699', isFocused: false },
    source: { value: 0, isFocused: false }
  };

  let isPending = false;

  let regions = [];
  let cities = [];
  let sources = [];

  let selectedRegion;
  let selectedCity;
  let checked = false;
  let selectedRetailer;

  let postalCode = '';

  currentLanguage.subscribe(value => {
    labels = languages[value];
    form.language.value = value;
  });

  onMount(() => {
    isPending = true;
    fetch(`${ENV.API_URL}regions`)
      .then(response => response.json())
      .then(data => {
        regions = data;
        isPending = false;
      })
      .catch(console.error);
    getSources();
  });

  const getSources = () => {
    fetch(`${ENV.API_URL}sources/`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(res => {
        sources = res;
      })
      .catch(console.error);
  };

  const search = (region, city, street, houseNumber) => {
    const params = [`${String(houseNumber)} ${String(street)}`, city, region, 'Belgium'];
    osmp
      .search({ query: params.filter(item => item).join(', ') })
      .then(places => {
        console.log(places);

        if (places && places.length) {
          form.lat.value = places[0].y;
          form.long.value = places[0].x;
        } else {
          form.lat.value = 50.5039;
          form.long.value = 4.4699;
        }
      })
      .catch(console.error);
  };

  const getCities = region => {
    isPending = true;
    postalCode = '';
    cities = [];
    selectedRegion = region.name;
    fetch(`${ENV.API_URL}cities/?region=${region.id}`, {
      method: 'GET'
    })
      .then(response => response.json())
      .then(data => {
        cities = data;
        isPending = false;
      })
      .catch(console.error);
  };

  const selectCity = city => {
    selectedCity = city.detail.selectedCity;
    postalCode = selectedCity.postal_code;
    form.city.value = selectedCity.id;
  };

  const selectRetailer = retailer => {
    if (retailer.detail.retailer) {
      selectedRetailer = retailer.detail.retailer;
      form.retailer.value = selectedRetailer.id;
      form.source.value = sources.find(source => source.name === selectedRetailer.name).id;
    }
  };

  const emptyTags = val => {
    return val.length === 0;
  };

  const checkEmptyInput = val => {
    if (val.isFocused) {
      return emptyTags(val.value);
    } else {
      return false;
    }
  };

  const handleInputLength = (value, maxLength) => {
    if (value.isFocused) {
      return value.value.length < maxLength || value.value.length > maxLength;
    } else {
      return false;
    }
  };

  $: isFormInvalid = Object.values(form)
    .map(formValue => formValue.value)
    .some(value => value.length === 0);

  const checkEmail = email => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validateEmail = email => {
    if (email.isFocused) {
      return !checkEmail(email.value);
    } else {
      return false;
    }
  };

  const debounce = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      errorMessage = [];
    }, 5000);
  };

  const submitForm = () => {
    isPending = true;
    search(selectedRegion, selectedCity.name, form.address.value, form.address_number.value);
    const finalForm = Object.entries(form).reduce((outputObject, [key, value]) => {
      outputObject[key] = value.value;
      return outputObject;
    }, {});

    console.log(finalForm);
    fetch(`${ENV.API_URL}users/`, {
      method: 'POST',
      body: JSON.stringify(finalForm),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        if (response.status === 201) {
          successRegistration.set(true);
        }
        return response.json();
      })
      .then(response => {
        isPending = false;
        errorMessage = Object.values(response.error.message);
        debounce();
      })
      .catch(console.error);
  };
</script>

{#if isPending}
  <Loader />
{/if}

<div class="form-container">
  <form on:submit|preventDefault={submitForm}>
    <h1 class="register">{labels.register}</h1>

    <div class="form-group">
      <label for="eanCode">{labels.ean_code}</label>
      <input
        id="eanCode"
        class="form-control"
        bind:value={form.ean_code.value}
        class:invalid-input={checkEmptyInput(form.ean_code) || handleInputLength(form.ean_code, 15)}
        on:focus|once={() => (form.ean_code.isFocused = true)}
        type="text"
      />
      {#if handleInputLength(form.ean_code, 15)}
        <p class="invalid-feedback">{labels.field_required}</p>
      {/if}
    </div>

    <div class="row form-group">
      <div class="col-6">
        <label for="firstName">{labels.first_name}</label>
        <input
          class="form-control"
          bind:value={form.first_name.value}
          class:invalid-input={checkEmptyInput(form.first_name)}
          id="firstName"
          on:blur|once={() => (form.first_name.isFocused = true)}
          type="text"
        />
        {#if checkEmptyInput(form.first_name)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
      </div>
      <div class="col-6">
        <label for="lastName">{labels.last_name}</label>
        <input
          class="form-control"
          bind:value={form.last_name.value}
          class:invalid-input={checkEmptyInput(form.last_name)}
          id="lastName"
          on:blur|once={() => (form.last_name.isFocused = true)}
          type="text"
        />
        {#if checkEmptyInput(form.last_name)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
      </div>
    </div>

    <div class="row form-group">
      <div class="col-6 region-select">
        <label for="region">{labels.regions}</label>
        <!--<select
          bind:value={selectedRegion}
          id="region"
          on:change={() => getCities(selectedRegion)}
          class="form-control"
        >
          <option value="">&#45;&#45; region &#45;&#45;</option>
          {#each regions as region (region.id)}
            <option value={region}>{region.name}</option>
          {/each}
        </select>-->
        <input id="region" autocomplete="off" type="text" class="form-control" readonly bind:value={selectedRegion} />
        <ul>
          {#each regions as city (city.id)}
            <li on:click={() => getCities(city)}>{city.name}</li>
          {/each}
        </ul>
      </div>
      <div class="col-6">
        <!--<label for="city">{labels.city}</label>-->
        <SearchSelect {cities} filteredCities={cities} labelsCity={labels.city} on:selectCity={selectCity} />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-6">
        <label for="street">{labels.address}</label>
        <input
          class="form-control"
          bind:value={form.address.value}
          class:invalid-input={checkEmptyInput(form.address)}
          id="street"
          on:blur={() => (form.address.isFocused = true)}
          type="text"
        />
        {#if checkEmptyInput(form.address)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
      </div>
      <div class="col-3">
        <label for="number">{labels.number_address}</label>
        <input
          class="form-control"
          bind:value={form.address_number.value}
          class:invalid-input={checkEmptyInput(form.address_number)}
          id="number"
          on:blur={() => (form.address_number.isFocused = true)}
          type="text"
        />
        {#if checkEmptyInput(form.address_number)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
      </div>
      <div class="col-3">
        <label for="postalCode">{labels.postal_code}</label>
        <input class="form-control" bind:value={postalCode} disabled id="postalCode" type="text" />
      </div>
    </div>

    <div class="row form-group">
      <div class="col-6">
        <label for="email">{labels.email}</label>
        <input
          class="form-control"
          bind:value={form.email.value}
          class:invalid-input={checkEmptyInput(form.email) || validateEmail(form.email)}
          id="email"
          on:focus|once={() => (form.email.isFocused = true)}
          type="email"
        />
        {#if checkEmptyInput(form.email)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
        {#if validateEmail(form.email) && !checkEmptyInput(form.email)}
          <p class="invalid-feedback">{labels.email_required}</p>
        {/if}
      </div>
      <div class="col-6">
        <label for="phone">{labels.phone}</label>
        <input
          class="form-control"
          bind:value={form.phone.value}
          class:invalid-input={checkEmptyInput(form.phone)}
          id="phone"
          on:blur={() => (form.phone.isFocused = true)}
          type="text"
        />
        {#if checkEmptyInput(form.phone)}
          <p class="invalid-feedback">{labels.field_required}</p>
        {/if}
      </div>
    </div>

    <div class="retailers form-group">
      <RetailersForm tableLabels={labels.table} on:selectRetailer={selectRetailer} />
    </div>

    {#if selectedRetailer}
      <div class="form-group">
        <label for="card_id">{labels.card_id}</label>
        <input
          class="form-control"
          bind:value={form.card_id.value}
          class:invalid-input={checkEmptyInput(form.card_id)}
          on:blur={() => (form.card_id.isFocused = true)}
          id="card_id"
          type="text"
        />
        {#if checkEmptyInput(form.card_id)}
          <p class="invalid-feedback">{labels.card_id}</p>
        {/if}
      </div>
    {/if}

    <div class="checkbox-block">
      <input type="checkbox" class="input-checkbox" bind:checked id="input-checkbox" />
      <label for="input-checkbox">
        {labels.IkGeef}
      </label>
    </div>

    <button class="submit-btn" disabled={isFormInvalid || !checked}>{labels.submit}</button>
  </form>
</div>

<div class="companies-logo-container">
  <div class="companies-logo">
    <div class="img-block">
      <img src="/assets/img/K_logo.webp" alt="K_logo" />
    </div>
    <div class="img-block">
      <img src="/assets/img/waasland.webp" alt="waasland" />
    </div>
    <div class="img-block">
      <img src="/assets/img/wijnegem.webp" alt="wijnegem" />
    </div>
  </div>
</div>

{#if errorMessage.length}
  <ErrorSnackbar errorMessage={errorMessage[0]} />
{/if}

<style>
  .invalid-input {
    border: 2px solid #ea4335 !important;
  }

  .invalid-feedback {
    font-size: 12px;
    margin: 4px 0 0;
    color: #dc3545;
  }
  .register {
    font-size: 30px;
    font-weight: 700;
    margin: 50px 0;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    height: 42px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #dbdee1;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    background-color: transparent;
    border-radius: 4px;
  }

  input:focus {
    border: 2px solid #89d12d;
  }

  input:disabled {
    background: rgba(0, 0, 0, 0.02);
  }

  label {
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
  }

  .submit-btn {
    font-weight: 600;
    border-radius: 3px;
    outline: none;
    font-size: 16px;
    padding: 0 50px;
    height: 50px;
    background-color: #264b1d;
    color: white;
    border: 1px solid #264b1d;
    margin: 0 auto;
    display: block;
    cursor: pointer;
  }

  .submit-btn:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .retailers {
    margin: 40px 0 20px;
  }

  .checkbox-block {
    display: flex;
  }

  .checkbox-block label {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  }
  .input-checkbox {
    margin-top: 5px;
    margin-right: 15px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
  }
  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 17px;
    top: 0;
    left: 0;
    background-color: #fff;
    border-radius: 2px;
    border: 2px solid #dbdee1;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 18px;
    height: 17px;
    top: 0;
    left: 0;
    background-color: #89d12d;
    border: 1px solid #89d12d;
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 7px;
  }

  .form-container {
    position: relative;
  }

  .companies-logo-container {
    position: absolute;
    top: 0;
    left: calc((100vw - 600px) / 2 + 600px);
  }

  .companies-logo {
    height: 100vh;
    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly;*/
    position: fixed;
    width: calc((100vw - 600px) / 2);
    align-items: flex-start;
  }

  .img-block {
    margin: 40px 40px 0 40px;
    display: flex;
    justify-content: center;
  }

  .img-block:first-child {
    margin: 100px 40px 0 40px;
  }

  img {
    max-width: 200px;
    width: 100%;
    height: auto;
  }

  #region:focus ~ ul {
    height: 100%;
  }

  .region-select ul {
    position: absolute;
    width: 245px;
    min-width: 112px;
    max-height: 200px;
    overflow: hidden;
    pointer-events: auto;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    color: rgba(0, 0, 0, 0.87);

    padding-left: 0;
    padding-top: 0;
    margin-top: 0;
    list-style: none;
    text-align: right;
    transition: all 0.4s ease-out;
    height: 0;
  }
  .region-select ul li {
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;

    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }

  .region-select ul li:hover {
    background: rgba(0, 0, 0, 0.04);
  }
</style>
