<script>
  import { fly } from 'svelte/transition';
  export let errorMessage;
</script>

<div class="snackbar-container" transition:fly={{ y: 0, duration: 200 }}>
  <div class="snackbar-block">
    <div class="error-container">
      <div class="error-snackbar">
        <p>{errorMessage}</p>
      </div>
    </div>
  </div>
</div>

<style>
  .snackbar-container {
    position: fixed;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .snackbar-block {
    justify-content: center;
    align-items: flex-end;
    display: flex;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
  .error-container {
    /*pointer-events: auto;*/
    position: static;
    margin-bottom: 0;
    box-sizing: border-box;
    z-index: 1000;
    display: flex;
    max-width: 100%;
    max-height: 100%;
  }

  .error-snackbar {
    border-radius: 4px;
    /*box-sizing: border-box;*/
    /*display: block;*/
    margin: 24px;
    max-width: 33vw;
    min-width: 344px;
    padding: 14px 16px;
    min-height: 48px;
    /*transform-origin: center;*/
    background: #d32f2f;
    color: white;
  }

  p {
    margin: 0;
  }
</style>
