<script>
  import { currentLanguage } from '../store/Store';
</script>

<header>
  <div class="container">
    <div class="logo">
      <img src="/assets/img/logo.jpg" alt="Logo" />
    </div>

    <div class="languages">
      <span class="language" on:click={() => currentLanguage.set('NL')}>NL</span>
      <span class="language" on:click={() => currentLanguage.set('FR')}>FR</span>
    </div>

    <!--<img class="izen-logo" src="./assets/img/izen-logo.png" alt="izen logo" />-->
  </div>
</header>

<style>
  header {
    background-color: white;
  }

  .container {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    /*justify-content: space-between;*/
  }

  .logo img {
    width: 200px;
  }

  .languages {
    margin-left: 27%;
  }

  .language {
    margin: 0 10px;
    text-decoration: none;
    cursor: pointer;
  }

  /* .izen-logo {
    height: 80px;
    margin-right: 90px;
    margin-bottom: -15px;
  } */
</style>
