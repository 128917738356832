<div class="account-email-sent">
  <div class="white-container">
    <img src="/assets/img/success-confirmation.svg" alt="confirm" />
    <h3 class="title">Email sent!</h3>
    <p class="mat-subheading-1">Check your email for a message with a link to activate your account</p>
    <a class="back-to" href="https://energycommunity.zeroemissionsourcing.com">
      <img src="/assets/img/Back.svg" class="icon" alt="back" />Back to Log in
    </a>
  </div>
</div>

<style>
  .account-email-sent {
    padding: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .white-container {
    background-color: white;
    border-radius: 5px;
    padding: 50px 100px 40px;
    width: 600px;
  }

  img {
    width: 300px;
    margin-bottom: 40px;
  }

  .title {
    margin: 0 0 20px;
    font-weight: normal;
    font-size: 30px;
  }

  p {
    margin: 0 0 30px;
    color: #737373;
    font-size: 16px;
  }

  .back-to {
    text-decoration: none;
    font-size: 16px;
    padding: 0 50px;
    height: 50px;
    background-color: #264b1d;
    color: white;
    /*border: 1px solid #264b1d;*/
    font-weight: 600;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    /*vertical-align: middle;*/
  }

  .icon {
    width: 20px;
    margin: 0 20px 0;
  }
</style>
