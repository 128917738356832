<script>
  import { afterUpdate, createEventDispatcher } from 'svelte';

  export let cities;
  export let filteredCities;
  export let labelsCity;
  let select;

  let invalidInput = false;

  const dispatch = createEventDispatcher();

  afterUpdate(() => {
    if (!cities.length) {
      select = '';
    }
  });

  const search = value => {
    const filter = value.toLowerCase();
    filteredCities = cities.filter(option => option.name.toLowerCase().includes(filter.toLowerCase()));
    invalidInput = !filteredCities.length;

    let selectedCity = filteredCities.find(city => city.name.toLowerCase() === value.toLowerCase());
    if (selectedCity) {
      selectCity(selectedCity);
    }
  };

  const selectCity = selectedCity => {
    select = selectedCity.name;
    dispatch('selectCity', { selectedCity });
    invalidInput = false;
  };

  const emptyTags = val => {
    invalidInput = val.length === 0;
  };
</script>

<div class="search-select">
  <label for="toggle">{labelsCity}</label>
  <input
    id="toggle"
    class:invalid-input={invalidInput}
    autocomplete="off"
    type="text"
    disabled={!cities.length}
    bind:value={select}
    on:input={() => search(select)}
    on:focus={() => emptyTags(select)}
  />
  <ul>
    {#each filteredCities as city (city.id)}
      <li on:click={() => selectCity(city)}>{city.name}</li>
    {/each}
  </ul>
</div>

<style>
  .search-select {
    z-index: 1000;
    min-width: 120px;
  }

  .invalid-input {
    border: 2px solid #ea4335 !important;
  }

  input {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    height: 42px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #dbdee1;
    /*transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
    width: 100%;
    background-color: transparent;
    border-radius: 4px;
  }

  input:focus {
    border: 2px solid #89d12d;
  }

  input:disabled {
    background: rgba(0, 0, 0, 0.02);
  }

  #toggle:focus ~ ul {
    height: 100%;
  }

  .search-select ul {
    position: absolute;
    width: 245px;
    min-width: 112px;
    max-height: 256px;
    overflow: auto;
    pointer-events: auto;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    color: rgba(0, 0, 0, 0.87);

    padding-left: 0;
    padding-top: 0;
    margin-top: 0;
    list-style: none;
    text-align: right;
    transition: all 0.4s ease-out;
    height: 0;
  }
  .search-select ul li {
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    text-align: left;

    position: relative;
    display: inline-block;
    width: 100%;
    cursor: pointer;
  }

  .search-select ul li:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  label {
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
  }
</style>
