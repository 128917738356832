const PROD = {
  API_URL: 'https://energycommunity.zeroemissionsourcing.com/api/'
};

const DEV = {
  API_URL: 'https://energycommunity.zeroemissionsourcing.com/api/'
};

const ENV = process.env.BUILD_ENV === 'production' ? PROD : DEV;

export default ENV;
