<footer class="main-footer">
  <div class="container">
    <p class="powered-by">
      Powered by <strong><a href="https://energymarketprice.com">EnergyMarketPrice</a></strong>
    </p>
  </div>
</footer>

<style>
  .main-footer {
    /*position: sticky;*/
    bottom: 0;
    padding: 20px 0 5px;
    border-top: 1px solid #ccc;
    position: relative;
  }

  .main-footer::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    border-top: 1px solid #ddd;
  }

  .powered-by {
    color: grey;
    font-size: 15px;
    text-align: center;
  }
</style>
