<div class="global-spinner-container">
  <div class="lds-rolling">
    <div class="flex">
      <div />
    </div>
  </div>
</div>

<style>
  @keyframes lds-rolling {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .global-spinner-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100000;
  }

  .lds-rolling {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px !important;
    height: 100px !important;
    transform: translate(-50%, -50%);
    border: 2px solid white;
    border-radius: 50%;
  }

  .flex {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  .lds-rolling div div {
    width: 100%;
    height: 100%;
    border: 6px solid #eaeaea;
    border-top-color: transparent;
    border-radius: 50%;
    animation: lds-rolling 1s linear infinite;
  }
</style>
