<script>
  import RegistrationForm from './RegistrationForm.svelte';
  import TextIntro from './TextIntro.svelte';
</script>

<div class="registration-page">
  <TextIntro />
  <RegistrationForm />
</div>

<style>
  .registration-page {
    padding: 50px 40px;
    background-color: white;
    border-radius: 5px;
  }
</style>
